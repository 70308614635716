@import './_variables';

// customize bootstrap variables
// $theme-colors: ();

$grid-gutter-width: 3rem;

$min-contrast-ratio: 3;

$primary: $opensea-blue;

$nav-link-color: $dark;
$nav-link-hover-color: $black;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $dark;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'a Alloy Ink';
  src: url('/fonts/aAlloyInk.woff2') format('woff2'),
    url('/fonts/aAlloyInk.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.title {
  font-family: 'a Alloy Ink';
}

.pink-gradient {
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 162, 206, 1) 0%,
    rgba(255, 255, 255, 1) 100vh
  );
}

.blue-gradient {
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(135, 206, 250, 1) 0%,
    rgba(255, 255, 255, 1) 100vh
  ); 
}

.text-pink {
  color: $pink;
}
a.text-pink {
  color: $pink;
  &:hover,
  &:focus,
  &:active {
    color: shade-color($pink, $link-shade-percentage);
  }
}

.btn-pink {
  @include button-variant($pink, $pink);
}
.btn-outline-pink {
  @include button-outline-variant($pink, $pink);
}
