$opensea-blue: #2081e2;

$rainbow-pink: #ffa2ce;
$rainbow-yellow: #ffe771;

$pink: #fa6bae;

$white: #fff;
$black: #000;

$dark: #212529;
